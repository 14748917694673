<script setup></script>

<template>
  <div>
    <p-header class="hidden lg:block" />
    <div class="h-19 flex items-center justify-center lg:hidden">
      <nuxt-link
        to="/"
        class="flex flex-row space-x-6 items-center place-content-start"
      >
        <p-icon-light-logo class="!w-10 !h-10" />
        <div class="text-4xl font-lato font-semibold text-primary-400">
          Позитив
        </div>
      </nuxt-link>
    </div>
    <slot />
    <p-footer />
  </div>
</template>
